<script setup lang="ts">
    import { formateCompanyReference } from '@/utils/functions';
import { Ref, onMounted, onUnmounted, ref, watch } from 'vue';

    // Props
    const props = withDefaults(defineProps<{
        id: string
        onInput?: Function
        onBlur?: Function
        onChange?: Function
        inputLabel?: string
        placeholder?: string
        type?: string
        errors?: string | null
        value: string
        classes?: any[]
        disabled?: boolean
        maxlength?: string
        autocomplete?: string,
        isSiret?: boolean
    }>(), {
        type: 'text',
        errors: null,
        disabled: false,
        isSiret: false,
    })

    // Emits
    const emit = defineEmits(['update:value'])

    // Refs
    const passwordInputType = ref('password')
    const hidePassword = ref(true)
    const inputValue = ref(props.value)
    let classList: Ref<any> = ref(props.classes)

    // Lifecycle Hooks
    onMounted(() => {
        if (props.errors) {
            classList.value.push('border-danger')
        }

        if (props.isSiret) {
            const inputElement = document.getElementById(props.id);
            if (inputElement) {
                inputElement.addEventListener('keypress', isNumber);
            }
        }
    })

    onUnmounted(() => {
        if (props.isSiret) {
            const inputElement = document.getElementById(props.id);
            if (inputElement) {
                inputElement.removeEventListener('keypress', isNumber);
            }
        }
    })

    // Watchers
    watch(() => props.value, (newValue) => {
        inputValue.value = newValue
    })

    // Functions
    const onInput = (event: any) => {
        let value = event.target.value
        if (props.isSiret) {
             value = formatSiret(value)
        }
        emit('update:value', value)
        if (props.onInput) {
            props.onInput()
        }
    }

    const formatSiret = (value: string) => {
        return formateCompanyReference(value);
    }
    

    const isNumber = (event: KeyboardEvent) => {
        const char = String.fromCharCode(event.keyCode)
        if (!/[0-9]/.test(char)) {
            event.preventDefault()
        }
    }

    function showPasswordToggle() {
        if (passwordInputType.value === 'password') {
            passwordInputType.value = 'text'
            hidePassword.value = false
        } else if (passwordInputType.value === 'text') {
            passwordInputType.value = 'password'
            hidePassword.value = true
        }
    }

    let onBlurHandlaer = (payload: FocusEvent) => {
        if (props.onBlur) {
            props.onBlur()
        }
    }
</script>

<template>
    <div v-if="type === 'password'">
        <label 
            v-if="inputLabel" 
            class="form-label" :for="id">{{ inputLabel }}</label>
        <div style="position: relative;">
            <input 
                :type="passwordInputType" 
                :id="id"
                :maxlength="maxlength"
                @blur="onBlurHandlaer"
                :value="value"
                :disabled="disabled"
                @input="onInput" 
                class="form-control text-start mb-0"
                :class="classList"
                :autocomplete="autocomplete"
                :placeholder="placeholder"/>
            <span 
                id="togglePassword" 
                @click="showPasswordToggle" 
                style="right: 10px; top: 4px; position: absolute; cursor: pointer; z-index: 100;">
                <eye-off-outline v-if="hidePassword" :size="18" />
                <eye-outline v-else :size="18" />
            </span>
        </div>
    </div>
    <div v-else style="position: relative;">
        <label 
            v-if="inputLabel" class="form-label" 
            :for="id">{{ inputLabel }}</label>
        <input
            :type="type" 
            :id="id"
            :maxlength="maxlength"
            v-model="inputValue"
            :disabled="disabled"
            @input="onInput"
            :autocomplete="autocomplete"
            class="form-control text-start mb-0" 
            :class="classList"
            :placeholder="placeholder">
    </div>
    
    <p class="mb-0 text-danger" v-if="errors">{{ errors }}</p>
</template>

<style scoped>
    @import url('@/assets/css/bootstrap-extended.css');

    .form-check-input:checked {
        background-color: #FB9307;
        border-color: #FB9307;
    }

    .form-control:focus {
        border-color: #FB9307;
        box-shadow: 0 0 0 0.02rem #FB9307;
        /* padding-left: 1.5rem; */
    }
</style>